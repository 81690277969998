import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import type { FC, PropsWithChildren } from "react";

import Button from "../Button/Button.js";
import Header from "../Typography/Header.js";
import Paragraph from "../Typography/Paragraph.js";
import CenteredCard from "./CenteredCard.js";

declare global {
  interface Window {
    ENV?: any;
  }
}

const defaultStatusText: Record<string, string> = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
};

const ErrorBoundaryCard: FC<PropsWithChildren> = ({ children }) => {
  const error = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    let statusText = error.statusText;

    if (!statusText || statusText === "") {
      statusText = defaultStatusText[error.status] ?? "Unknown Error";
    }

    return (
      <CenteredCard>
        <Header>
          {statusText} - {error.status}
        </Header>
        <Paragraph>{error.data}</Paragraph>
        {children}
        <Button to="/s">Go Home</Button>
      </CenteredCard>
    );
  }

  let errorMessage = "Unknown error";
  if (error instanceof Error) {
    if ("message" in error) {
      errorMessage = error.message;
    }
  }

  return (
    <CenteredCard>
      <Header>Uh oh ...</Header>
      <p>Something went wrong.</p>
      <div className="break-all font-mono text-xs">{errorMessage}</div>
    </CenteredCard>
  );
};

export default ErrorBoundaryCard;
